import { useRouter } from "next/navigation"
import ModalWrapper from "../../Modal/ModalWrapper"
import { Dialog } from "@headlessui/react"
import Typography from "../../typography"
import { FieldsWithPercentages, FieldsWithPercentagesType } from "src/utils/helpers/profile/countEmptyFieldsPercentage"
import Image from "next/image"

type FieldsToCheck = {
  about_me: string
  work_experience: string
  education: string
  skills: string
  soft_skills: string
  certificates: string
  trainings: string
}

interface Props {
  modalOpen: boolean
  setModalOpen: (modalOpen: boolean) => void
  emptyFields: Partial<FieldsToCheck>
}

const ShowMoreModal = ({ modalOpen, setModalOpen, emptyFields }: Props) => {
  const router = useRouter()

  const handleClick = (value: string) => {
    sessionStorage.setItem("ProgressCardClick", "clicked")
    router.push(`/profile?param=${value}`)
  }

  return (
    <ModalWrapper
      isOpen={modalOpen}
      onClose={() => setModalOpen(false)}
      width="604px"
      className="justify-start p-6 text-start md:px-8 md:py-6 h-[560px] overflow-y-auto custom-scroll"
    >
      <div>
        <Dialog.Title className="flex bg-white pb-4 flex justify-between">
          <Typography type="h4" weight="bold">
            დაასრულე პროფილის შექმნა
          </Typography>
          <div className="cursor-pointer" onClick={() => setModalOpen(false)}>
            <Image src="/images/close.svg" width={20} height={20} alt="arrow" />
          </div>
        </Dialog.Title>
      </div>
      <hr className="mb-6" />
      <div className="flex flex-col gap-6 ">
        {Object.entries(emptyFields).map(([key, value], index) => (
          <div
            className={index !== Object.keys(emptyFields).length - 1 ? "border-b cursor-pointer" : "cursor-pointer"}
            onClick={() => handleClick(key)}
            key={key + " modal content"}
          >
            <div className="flex justify-between items-center gap-3">
              <div className="flex items-center gap-3 pb-2">
                <Image src="/images/circle.svg" width={20} height={20} alt="circle" />
                <Typography type="h5" weight="bold" className="cursor-pointer">
                  დაამატე {value}
                </Typography>
              </div>
              <button className="pt-5" onClick={() => handleClick(key)}>
                <Image src="/images/arrow_right.svg" width={8} height={12} alt="arrow" />
              </button>
            </div>
            <Typography type="text" className="text-secondary-100 pb-3 cursor-pointer">
              სრულყოფილი პროფილის {FieldsWithPercentages[key as keyof FieldsWithPercentagesType]}%
            </Typography>
          </div>
        ))}
      </div>
    </ModalWrapper>
  )
}

export default ShowMoreModal
